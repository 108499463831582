<div class="card card--about card--green">
    <div class="card--headline"><a href="/about/">About</a></div>
    <div class="card--body">
      <div class="card--definition-list">
        <div class="card--definition">
          <div class="card--definition-text">Who’s behind this site — and how you can contribute.</div>
        </div>
      </div>
    </div>
    <div class="card--actions">
      <div class="card--actions-border">
        <div class="card--action-item card--expand"><a aria-label="Learn more" href="/about/">Learn more</a></div>
      </div>
    </div>
</div>